@use "styles" as *;

.carouselContainer {
  position: relative;
}

.wrapperSlide {
  padding: 1px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: 12px;
}

.arrows {
  margin: 0 -20px;
}
