@use "styles" as *;

.container {
  padding: 40px 0;
  overflow: hidden;

  &.white {
    background-color: $white;
  }
  &.pink {
    background-color: $light-pink;
  }
}

.title {
  @include text-big-title;
  text-align: center;
  margin-bottom: 24px;
  @include sm-desktop {
    margin-bottom: 32px;
  }
}

.wrapperLinks {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.link {
  display: flex;
  align-items: center;
  gap: 4px;
  @include text-s-regular;
  @include text-underline;
}

.carouselContainer {
  position: relative;
}

.wrapperSlide {
  padding: 1px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: 12px;
}

.arrows {
  display: none;
  @include md-desktop {
    margin: 0 -20px;
    display: flex;
  }
}
