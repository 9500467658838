@use "styles" as *;

.wrapper {
  display: flex;
  padding: 24px 0;
  text-align: center;
}

.grid {
  width: 100%;
  @include sm-desktop {
    align-items: center;
  }
}

.col1 {
  grid-column: 1 / span 12;
  display: flex;
  flex-direction: column;
  @include sm-desktop {
    // display: block;
    padding: 0 24px;
    grid-column: 1 / span 5;
    // margin-top: 36%;
    // align-self: flex-start;
  }
}

.col2 {
  grid-column: 1 / span 12;
  @include sm-desktop {
    padding: 0 36px;
    grid-column: 6 / span 7;
  }
  @mixin lg-desktop {
    padding: 0 46px;
  }
}

.introText {
  @include text-xxl-extralight-uppercase;
}

.thumbs {
  // default, override via react
  --thumb-duration: 3s;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 16px;
  @include sm-desktop {
    gap: 24px;
    // justify-content: start;
  }
}

.thumb {
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;

  img {
    object-fit: cover;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include animate;
  }
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include animate;
  }

  &.active {
    img {
      filter: blur(10px);
      opacity: 0.5;
    }
    .playState {
      opacity: 1;
    }
  }

  @include sm-desktop {
    &:not(.active):hover {
      .circle {
        stroke-dasharray: 1.1px 1px;
      }
    }
  }
}

.playState {
  z-index: 1;
  opacity: 0;
}

@keyframes fillCircle {
  from {
    stroke-dasharray: 0px 1px;
  }
  to {
    stroke-dasharray: 1px 1px;
  }
}

.circle {
  transform: rotate(270deg);
  transform-origin: center;
}

.animateCircle {
  animation: fillCircle var(--thumb-duration) linear;
  animation-play-state: paused;
  &.playing {
    animation-play-state: running;
  }
}

.slideWrapper {
  position: relative;
}
