@use "styles" as *;

.containerCard {
  grid-column: span 6;
  @include rounded-12;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include container-shadow;
  backdrop-filter: blur(10px);

  @include sm-desktop {
    grid-column: span 3;
    @include rounded-16;
  }

  &.firstCardIsHighlighted {
    grid-column: span 12;
    display: grid;
    grid-template-columns: 1fr;
    gap: 28px;
    align-items: center;
    text-align: center;
    box-shadow: none;
    backdrop-filter: none;

    @include sm-desktop {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.cardImg {
  position: relative;
  aspect-ratio: 1;
  img {
    object-fit: cover;
  }

  &.cardImgHighlighted {
    @include rounded-12;
    overflow: hidden;
    @include sm-desktop {
      aspect-ratio: 16 / 9;
      @include rounded-16;
    }
  }
}

.containerText {
  padding: 16px 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;

  &.containerTextHighlighted {
    padding: 0;
    align-items: center;
  }
}

.date {
  @include text-xs-semibold-uppercase-cta;
  @include rounded-24;
  @include container-shadow;
  backdrop-filter: blur(10px);
  padding: 4px 10px;
  width: fit-content;
  margin-bottom: 4px;
}

.tag {
  @include text-xs-semibold-uppercase-cta;
  @include color-gradient-light;
  padding: 4px 10px;
  width: fit-content;
  @include rounded-20;
  line-height: 140%;
}

.title {
  margin-bottom: 4px;
  @include text-l-regular;
  &.titleIsBig {
    @include sm-desktop {
      @include text-xl-regular;
    }
  }

  &.titleHighlighted {
    @include text-xxl-regular;
    @include sm-desktop {
      @include text-3xl-regular;
    }
  }
}

.titleTextHighlight {
  margin-bottom: 4px;
  @include text-medium-title;
}

.description {
  margin-bottom: auto;
  @include text-m-regular;
}

.discoverMore {
  @include text-xs-regular;
  @include text-underline;
  line-height: 140%;
}
