@use "styles" as *;

.mosaic {
  display: grid;
  gap: 12px;
  margin-block-start: 24px;
  @include sm-desktop {
    gap: 1rem;
    margin: 0 auto;
    &:not(.mosaic_4, .mosaic_7) {
      max-width: 800px;
    }
  }
  &.flipMargin {
    margin-block-start: 0;
    margin-block-end: 24px;
  }
}

.mosaic_2,
.mosaic_8 {
  grid-template-columns: repeat(2, 1fr);
}

.mosaic_5 {
  grid-template-columns: repeat(2, 1fr);

  .mosaicMedia:nth-child(3) {
    grid-column: 1 / 3;
  }
  @include sm-desktop {
    grid-template-columns: 32% auto;
    .mosaicMedia {
      &:nth-child(1) {
        align-self: end;
      }
      &:nth-child(3) {
        grid-column: 2;
        grid-row: 1 / 3;
      }
    }
  }
}

.mosaic_6 {
  grid-template-columns: repeat(2, 1fr);

  .mosaicMedia:nth-child(1) {
    grid-column: 1 / 3;
  }
  @include sm-desktop {
    grid-template-columns: auto 32%;
    .mosaicMedia {
      &:nth-child(1) {
        grid-column: 1;
        grid-row: 1 / 3;
      }
      &:nth-child(2) {
        align-self: end;
      }
    }
  }
}

.mosaic_7 {
  grid-template-columns: repeat(12, 1fr);
  margin-block-end: 24px;
  background: url("../../../../public/backgrounds/mosaic_7-background-mobile.png") no-repeat;
  background-size: 100% 100%;

  .mosaicMedia {
    &:nth-child(1) {
      grid-column: span 6;
    }
    &:nth-child(2) {
      grid-column: span 6;
      grid-row: 1 / 3;
      align-self: center;
    }
    &:nth-child(3) {
      grid-column: span 6;
    }
    &:nth-child(n + 4) {
      display: none;
    }

    &.threeMosaicMedia {
      &:nth-child(2) {
        grid-column: 7 / span 6;
      }
    }

    &.fourMosaicMedia {
      &:nth-child(1) {
        grid-column: 4 / span 6;
      }
      &:nth-child(2) {
        grid-row: auto;
      }
    }
  }

  @include sm-desktop {
    align-items: center;
    padding: 32px 0;
    margin-block-end: 0;
    background: url("../../../../public/backgrounds/mosaic_7-background-desktop.png") no-repeat 48% 50%/80% 135%;

    .mosaicMedia {
      &:nth-child(1) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
      &:nth-child(n + 2) {
        grid-row: auto;
        grid-column: span 2;
      }
      &:nth-child(n + 4) {
        display: block;
        grid-column: span 2;
      }

      &.threeMosaicMedia {
        &:nth-child(1) {
          grid-column-start: 4;
          grid-column-end: 6;
        }
        &:nth-child(2) {
          grid-column: span 2;
        }
      }

      &.fourMosaicMedia {
        &:nth-child(1) {
          margin: 0;
          grid-column-start: 3;
          grid-column-end: 5;
        }
      }
    }
  }
}

.mosaicMedia {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  img {
    object-fit: cover;
  }

  &.arrowsMosaic_9 {
    @include sm-desktop {
      padding: 0 36px;
    }
  }
}

.mosaicRow {
  display: flex;
  gap: 12px;
  flex-grow: 1;
  .mosaicMedia {
    flex-basis: 0;
  }
  @include sm-desktop {
    gap: 1rem;
  }
}
