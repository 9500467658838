@use "styles" as *;

.background {
  padding: 24px 0;

  &.white {
    background-color: $white;
  }
  &.pink {
    background-color: $light-pink;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  grid-column: 1 / span 12;

  @include sm-desktop {
    grid-column: 2 / span 10;
  }

  &.gradientBackground {
    background-color: $light-pink;
  }

  &.whiteBackground {
    background-color: $white;
  }
}

.table {
  width: 100%;
  grid-template-columns: 0.6fr 1fr;
  display: flex;

  @include sm-desktop {
    display: grid;
    // padding: 0 12px;
    gap: 12px;
  }
}

.columnHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px 12px;
  text-align: center;
  @include color-gradient-light;
  @include rounded-8;
  @include sm-desktop {
    gap: 8px;
    padding: 8px;
  }
}

div.column {
  display: grid;
  gap: 12px;
  width: 100%;
  position: relative;
  flex-basis: calc(100vw + 32px);

  @include sm-desktop {
    &:not(:last-child)::after {
      content: "";
      display: block;
      height: calc(100% + 6px);
      width: 1px;
      @include color-gradient-light;
      position: absolute;
      inset-inline-end: -6.5px;
    }
  }
}

.slideColumnsContainer {
  .column {
    flex-shrink: 0;
    padding: 0 12px;
    flex-basis: auto;
  }

  @include sm-desktop {
    display: flex;
    gap: 12px;
    .column {
      flex-shrink: 1;
      padding: 0;
    }
  }
}

.rowFirstColumn {
  width: 100%;
  padding: 8px;
  @include color-gradient-light;
  @include rounded-8;
  @include text-xs-regular;
  display: flex;
  align-items: center;
  position: relative;

  @include sm-desktop {
    @include text-m-regular;
    padding: 16px 8px;
  }
}

.row {
  width: 100%;
  padding: 8px;
  background: $white;
  @include rounded-8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.rowFirstColumn,
.row {
  &:not(:last-child)::after {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% + 12px);
    @include color-gradient-light;
    position: absolute;
    inset-block-end: -5.5px;
  }

  @include sm-desktop {
    &:last-child::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% + 12px);
      @include color-gradient-light;
      position: absolute;
      inset-block-end: -5.5px;
    }
  }
}

.rowFirstColumn {
  &:not(:last-child)::after {
    inset-inline-start: 0;
  }

  @include sm-desktop {
    &:last-child::after {
      inset-inline-start: 0;
    }
  }
}

.row {
  &:not(:last-child)::after {
    inset-inline-end: 0;
  }
  @include sm-desktop {
    &:last-child::after {
      inset-inline-end: 0;
    }
  }
}

.columnTitle {
  @include text-m-bold;
  @include sm-desktop {
    @include text-l-semibold;
  }
}

.columnSubtitle {
  @include text-xs-regular;
  @include sm-desktop {
    @include text-m-regular;
  }
}

.iconContainer {
  @include rounded-20;
  border: solid 2px transparent;
  @include kisses-gradient;

  .icon {
    @include rounded-20;
    background: $white;
    padding: 2px;
  }
}

.swiperButtonPrev,
.swiperButtonNext {
  position: absolute;
  top: 4%;
  cursor: pointer;
  z-index: 1;
  padding: 1px;
  border: 1px solid $white;
  @include rounded-20;
  @include glass-gradient;
  @include container-shadow;
  transition: all 0.3s ease-in-out;
  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.swiperButtonPrev {
  inset-inline-start: 0;
}

.swiperButtonNext {
  inset-inline-end: 0;
}
