@use "styles" as *;

.background {
  background: url("../../../../public/backgrounds/editorial-banner-mobile.png") no-repeat 0 -100%/100% 90%;
  @include sm-desktop {
    background: url("../../../../public/backgrounds/editorial-banner-desktop.png") no-repeat -10% 300%/70% 95%;
  }

  &.backgroundWithFlipTextOnMobile {
    background: url("../../../../public/backgrounds/editorial-banner-mobile.png") no-repeat 0 300%/100% 90%;
    @include sm-desktop {
      background: url("../../../../public/backgrounds/editorial-banner-desktop.png") no-repeat -10% 300%/70% 95%;
    }
  }

  &.backgroundWithMirrorTextOnDesktop {
    @include sm-desktop {
      transform: scaleX(-1);
    }
  }

  @include rtl-only {
    transform: scaleX(-1);
  }
}

.container {
  padding: 24px 0;
}

.wrapper {
  display: flex;
  text-align: center;
  transform: inherit;
}

.grid {
  width: 100%;
  @include sm-desktop {
    align-items: center;
  }
}

.col1 {
  grid-column: 1 / span 12;
  display: flex;
  flex-direction: column;
  @include sm-desktop {
    padding: 0 24px;
    grid-column: 1 / span 5;
  }

  &.mirrorTextOnDesktop {
    @include sm-desktop {
      padding: 0 36px;
      grid-column: 8 / span 5;
    }
  }

  &.col1Mosaic7 {
    @include sm-desktop {
      padding: 0;
      grid-column: 1 / span 12;
      margin: 0 auto;
      width: 50%;
    }
  }
}

.col2 {
  grid-column: 1 / span 12;
  @include sm-desktop {
    padding: 0 36px;
    grid-column: 6 / span 7;
  }
  @mixin lg-desktop {
    padding: 0 46px;
  }

  &.mirrorTextOnDesktop {
    @include sm-desktop {
      padding: 0 24px;
      grid-column: 1 / span 7;
      order: -1;
    }
  }

  &.col2Mosaic7 {
    @include sm-desktop {
      padding: 0;
      grid-column: 1 / span 12;
      order: 0;
      .descriptionBelowAsset {
        width: 50%;
        margin: 0 auto;
      }
    }
  }

  &.col2Mosaic9 {
    @include sm-desktop {
      padding: 0;
    }
  }
}

.pretitleMobile {
  @include text-xxl-extralight-uppercase;
  margin-bottom: 28px;
  grid-column: 1 / span 12;

  &.bigTitle {
    @include text-3xl-extralight-uppercase;
  }
}

.pretitleDesktop {
  @include text-xxl-extralight-uppercase;
  margin-bottom: 24px;

  &.bigTitle {
    @include text-3xl-extralight-uppercase;
  }
}

.title,
.titleHighlighted {
  @include text-xxl-extralight;
  strong {
    @include text-xxl-semibold;
  }

  margin-bottom: 16px;
  @include sm-desktop {
    margin-bottom: 32px;
  }

  &.bigTitle {
    @include text-3xl-extralight;
    strong {
      @include text-3xl-semibold;
    }

    @include sm-desktop {
      margin-bottom: 24px;
    }
  }
}

.subtitle {
  @include text-m-regular;
  text-transform: uppercase;
  @include ltr-only {
    letter-spacing: 0.16em;
  }
  margin-bottom: 16px;
}

.paragraph {
  @include text-xl-regular;
  margin-top: 12px;
  margin-bottom: 16px;

  @include sm-desktop {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.description,
.descriptionBelowAsset {
  @include text-m-regular;
  b {
    @include ltr-only {
      letter-spacing: -0.03em;
    }
  }
}

.link {
  margin-top: 24px;
  width: fit-content;
  min-width: 200px;
  align-self: center;
}

.containerCountdown {
  margin: 32px 0 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.wrapperCountdown {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  padding: 8px 4px;
  @include rounded-8;
  @include glass-gradient;
}

.labelCountdown {
  @include text-xs-regular-uppercase;
}

.numberCountdown {
  @include text-xl-regular;
}
